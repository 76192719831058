import styled from "styled-components";

export const Button = styled.button`
  padding: 11px 53px;
  border-radius: 30px;
  border: 0px;
  background: #e5b910;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
`;


export const WalletOption = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  background-color: ${({ theme }) => theme.highlight2};
  margin-bottom: 1rem;
  cursor: pointer;
  border-radius: 8px;
  padding: 1rem;
`;
