import React, { useEffect } from "react";
import {
  CardContainer,
  Description,
  ValueDetail,
  DescriptionSubValue,
  DescriptionContainer,
  WalletAddress,
  CardTitle,
  ViewOption,
  StakingDiv,
  HideContainer,
} from "./stakingstyles.js";
import { ButtonState } from "../reusables/button";
import truncateAddress from "../methods/utils/truncate-address";
import {
  getFormDetails,
  staketogglemodal,
  getAvailableBalance,
  getMarketQuote,
  getAllStakingIds,
  getActionLimit,
  getlockPeriod,
  getPendingRewards,
} from "../methods/actions";
import { useSelector, useDispatch } from "react-redux";
import { numberWithCommaswithoutdecimals } from "../methods/helper";
import ClaimableRewards from "./claimablerewards.js";
import ConnectWallet from "../wallets/connectwallet";
import { lighttheme, darktheme } from "../../theme";

const StakingBalance = () => {
  const dispatch = useDispatch();
  const userStaked = useSelector((state) => state.StakingReducer.userStaked);
  const address = useSelector((state) => state.ConnectWalletReducer.address);
  const availableBalance = useSelector(
    (state) => state.General.availableBalance
  );
  const hidenumbers = useSelector((state) => state.General.hidenumbers);
  const usdQuote = useSelector((state) => state.MarketReducer.usdQuote);
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const theme = useSelector((state) => state.General.theme);
  const ids = useSelector((state) => state.StakingReducer.ids);
  const ownerAddress = useSelector(
    (state) => state.ConnectWalletReducer.address
  );
  useEffect(() => {
    dispatch(getAvailableBalance(tokenAddress));
    dispatch(getMarketQuote());
    dispatch(getAllStakingIds());
    dispatch(getActionLimit());
    dispatch(getlockPeriod());
    dispatch(getPendingRewards());
  }, [dispatch, tokenAddress, ownerAddress]);

  return (
    <CardContainer>
      <StakingDiv>
        <CardTitle>My WNT Staking</CardTitle>
        <ViewOption>
          {" "}
          {hidenumbers ? (
            <HideContainer
              onClick={() =>
                dispatch(
                  getFormDetails({
                    props: ["hidenumbers"],
                    value: !hidenumbers,
                  })
                )
              }
            >
              <svg width="20" height="16" viewBox="0 0 20 16" fill={!theme? lighttheme.highlight: darktheme.highlight} xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M10 0C12.0683 0 14.0293 0.717576 15.7366 2.04606C17.4439 3.36388 18.8976 5.29455 19.9415 7.70909C20.0195 7.89236 20.0195 8.10667 19.9415 8.28121C17.8537 13.1103 14.1366 16 10 16H9.99024C5.86341 16 2.14634 13.1103 0.0585366 8.28121C-0.0195122 8.10667 -0.0195122 7.89236 0.0585366 7.70909C2.14634 2.87903 5.86341 0 9.99024 0H10ZM10 4.12121C7.8439 4.12121 6.09756 5.85697 6.09756 8C6.09756 10.1333 7.8439 11.8691 10 11.8691C12.1463 11.8691 13.8927 10.1333 13.8927 8C13.8927 5.85697 12.1463 4.12121 10 4.12121Z" fill={!theme? lighttheme.highlight: darktheme.highlight} />
                <path d="M12.4308 7.99687C12.4308 9.32536 11.3381 10.4114 10.0015 10.4114C8.65518 10.4114 7.5625 9.32536 7.5625 7.99687C7.5625 7.83203 7.58201 7.67784 7.61128 7.52269H7.66006C8.74299 7.52269 9.62104 6.66936 9.66006 5.60172C9.76738 5.5833 9.88445 5.57263 10.0015 5.57263C11.3381 5.57263 12.4308 6.65869 12.4308 7.99687Z" fill={!theme? lighttheme.highlight: darktheme.highlight} />
              </svg>


              <p> View all numbers </p>
            </HideContainer>
          ) : (
            <HideContainer
              onClick={() =>
                dispatch(
                  getFormDetails({
                    props: ["hidenumbers"],
                    value: !hidenumbers,
                  })
                )
              }
            >
              <svg width="30" height="16" viewBox="0 0 30 30" fill={!theme? lighttheme.highlight: darktheme.highlight} xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.4" d="M15 4.85229H14.9878C9.82927 4.85229 5.18293 8.50728 2.57317 14.6358C2.47561 14.8697 2.47561 15.1404 2.57317 15.3619C3.41463 17.342 4.47561 19.0661 5.69512 20.4937L10.2073 15.9403C10.1585 15.645 10.122 15.325 10.122 15.005C10.122 12.2853 12.3049 10.0825 15 10.0825C15.3171 10.0825 15.6341 10.1194 15.9268 10.1686L20 6.05709C18.439 5.27071 16.7439 4.85229 15 4.85229ZM27.4268 14.6358C26.3049 12.0023 24.8049 9.81176 23.0366 8.18732L19.0244 12.2361C19.5488 13.0237 19.8659 13.9836 19.8659 15.005C19.8659 17.7124 17.6829 19.9153 15 19.9153C13.9878 19.9153 13.0366 19.5941 12.2561 19.0661L8.39024 22.9673C10.378 24.3825 12.6341 25.1578 14.9878 25.1578H15C20.1707 25.1578 24.8171 21.4905 27.4268 15.3619C27.5244 15.1404 27.5244 14.8697 27.4268 14.6358Z" fill={!theme? lighttheme.highlight: darktheme.highlight} />
                <path d="M25.2561 4.64266C25.622 4.99955 25.622 5.59025 25.2561 5.94714L23.0366 8.18689L19.0244 12.2357L17.2568 14.0188L17.6953 13.5776C17.9148 13.996 18.0367 14.4882 18.0367 15.0051C18.0367 16.6911 16.6709 18.0694 15.0002 18.0694C14.4885 18.0694 14.0011 17.9466 13.5867 17.7255L6.02443 25.3555C5.8415 25.5389 5.6098 25.625 5.37809 25.625C5.14638 25.625 4.90248 25.5389 4.73175 25.3555C4.42687 25.0478 4.37809 24.5543 4.62199 24.1975L4.65858 24.1482L17.7439 10.9447L21.6098 7.04363L22.6952 5.94714C22.7196 5.92252 22.7439 5.89791 22.7561 5.8733C22.7805 5.84869 22.8049 5.82407 22.8171 5.79946L23.9635 4.64266C24.3293 4.28578 24.9025 4.28578 25.2561 4.64266Z" fill={!theme? lighttheme.highlight: darktheme.highlight} />
              </svg>

              <p> Hide all numbers </p>
            </HideContainer>
          )}
        </ViewOption>
      </StakingDiv>
      <div className="col-lg-3 col-sm-3">
        <WalletAddress> {truncateAddress(address)}</WalletAddress>
      </div>

      <DescriptionContainer>
        <div>
          <Description> TOTAL STAKED</Description>
          <ValueDetail>
            {" "}
            {hidenumbers
              ? "-----"
              : numberWithCommaswithoutdecimals(userStaked) + " WNT"}{" "}
            <DescriptionSubValue>
              {" "}
              {hidenumbers ? (
                ""
              ) : (
                <i className="fa fa-arrows-h" aria-hidden="true" />
              )}
              {hidenumbers
                ? ""
                : `$${numberWithCommaswithoutdecimals(usdQuote * userStaked)}`}
            </DescriptionSubValue>{" "}
          </ValueDetail>
        </div>

        <div>
          <Description> AVAILABLE IN WALLET</Description>
          <ValueDetail>
            {" "}
            {hidenumbers
              ? "-----"
              : numberWithCommaswithoutdecimals(availableBalance) + " WNT"}{" "}
            <DescriptionSubValue>
              {" "}
              {hidenumbers ? (
                ""
              ) : (
                <i className="fa fa-arrows-h" aria-hidden="true" />
              )}
              {hidenumbers
                ? ""
                : `$${numberWithCommaswithoutdecimals(
                  usdQuote * availableBalance
                )}`}
            </DescriptionSubValue>{" "}
          </ValueDetail>
        </div>
      </DescriptionContainer>
      {address ? <ClaimableRewards /> : null}
      {address ? (
        <div className="row mb-3 padbtn">
          <ButtonState
            fontSize={"0.8rem"}
            buttonClass="primary"
            label={"Stake"}
            onClick={() => dispatch(staketogglemodal(1))}
          />
        </div>
      ) : (
        <div className="row t-center">
          <div clasName="col-lg-12">
            <ConnectWallet />
          </div>
        </div>
      )}
      {ids.length > 0 ? (
        <div className="row padbtn">
          <ButtonState
            fontSize={"0.8rem"}
            buttonClass="secondary"
            label={"UnStake"}
            onClick={() => dispatch(staketogglemodal(2))}
          />
        </div>
      ) : (
        <div> </div>
      )}
    </CardContainer>
  );
};

export default StakingBalance;
