import React, { FC, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../reusables/connectmodal";
import styled from "styled-components";
import connectors from "../methods/utils/connector-config";
import { disconnect } from "../methods/actions/contract-setup";
import { login } from "../methods/utils/useAuth";
import { connectorLocalStorageKey } from "../methods/utils/config";

import { togglemodal } from "../methods/actions";
import { WalletOption } from "./walletstyles";
import truncateAddress from "../methods/utils/truncate-address";

const ConnectionModal = () => {
  const dispatch = useDispatch();

  const [walletLogo, setWalletLogo] = useState("");
  const theme = useSelector((store: any) => store.General.theme);
  const modalState = useSelector((store: any) => store.General.modalState);
  const tokenAddress = useSelector((store: any) => store.General.tokenAddress);
  const {allStaked, userStaked} = useSelector((store: any) => store.StakingReducer);
  
  const address = useSelector(
    (store: any) => store.ConnectWalletReducer.address
  );
  const walletInUse = useSelector(
    (store: any) => store.ConnectWalletReducer.walletInUse
  );
  const apys = useSelector((store: any) => store.ConnectWalletReducer.apys);
  const [selectableApys, setSelectableApys] = useState<Array<any>>([]);
  

  const [connectInfo, setConnectInfo] = useState({
    network: null,
    protocol: null,
    wallet: null,
    //chainId: 80001 testnet
    //chainId: 137
    chainId: 42161
  });

  const disconnectWallet = () => {
    togglemodal(false);
    dispatch(disconnect());
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  useEffect(() => {
    // console.log(apys)
    if (connectInfo.network) {
      const x = apys.filter(b => b.network === connectInfo.network);
      // console.log(x, ' the x')
      setSelectableApys(x);
    }
  }, [apys, connectInfo]);

  useEffect(() => {
    const connectedWallet = connectors.filter(x => x.title === walletInUse);
    connectedWallet[0] && setWalletLogo(connectedWallet[0].image);
  }, [address, walletInUse]);

  return (
    <>
      {!address ? (
        <Modal
          title="Connect Wallet"
          desc=""
          visible={modalState}
          width={"30%"}
          close={() => togglemodal(false)}
          icon={theme ? "../assets/connectionicondark.svg" : "../assets/connectIcon.svg"}
        >
          <SectionWrapper>
            <SectionBody>
              {connectors.map((entry, i) => (
                <WalletOption key={i}
                  onClick={() => {
                    dispatch(
                      login(
                        entry.connectorId,
                        connectInfo.chainId,
                        entry.title,
                        tokenAddress
                      )
                    );

                    window.localStorage.setItem(
                      connectorLocalStorageKey,
                      entry.connectorId
                    );
                    // togglemodal(false);
                    //window.location.reload();
                  }}
                >
                  <div key={i} style={{display: "flex", alignItems: "center", gap: "1rem"}} >
                  <CardWrapper
                    key={i}
                    style={{backgroundColor: entry.title.toLowerCase() === "metamask" ? "#FFD2B0" : "#d5d5d5"}}
                  >
                    <img src={entry.image} alt={entry.title} />
                  </CardWrapper>
                  <WalletName>{entry.title}</WalletName>
                  </div>
                  {entry.title.toLowerCase() === "madwallet" && <Recommended> Recommended</Recommended>}
                </WalletOption>
              ))}
            </SectionBody>
          </SectionWrapper>
          <div>
            <InfoWrapper>
              By connecting a wallet, you agree to <br/>
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
              >
                {" "}
                (Wicrypt's user agreement)
              </a>{" "}
            </InfoWrapper>
          </div>
        </Modal>
      ) : (
        <Modal
          title="Disconnect wallet"
          desc={`You are connected with ${walletInUse}`}
          width={"30%"}
          visible={modalState}
          close={() => togglemodal(false)}
          icon={theme ? "../assets/connectionicondark.svg" : "../assets/connectIcon.svg"}
        >
          <SectionWrapper>
            <ConnectedWalletDetail>
              <div>
                <DetailHeading> Wallet Connected</DetailHeading>
                <DetailValue><span> <img src={walletLogo} alt="arbitrum" /></span> {walletInUse}</DetailValue>
              </div>
              <div>
                 <DetailHeading> Network Connected</DetailHeading>
                 <DetailValue><span> <img src={"./assets/arbitrum.svg"} alt="arbitrum" /></span> Arbitrum</DetailValue>
              </div>
            </ConnectedWalletDetail>
            <ConnectedWalletContainer>
              <Address> {truncateAddress(address)}</Address>
              <DetailHeading> Balance</DetailHeading>
              <div className="mt-2">
                <Balance>{userStaked}</Balance>
                <DetailValue> ARB <span> <img src={"./assets/arbitrum.svg"} alt="wicrypt icon" /></span></DetailValue>
              </div>
              <div>
                <Balance>{allStaked}</Balance>
                <DetailValue>  WNT <span><img src={"./assets/tokenIcon.svg"} alt="wicrypt icon" /></span></DetailValue>
              </div>
            </ConnectedWalletContainer>

          </SectionWrapper>
          <ActionContainer>
            <div onClick={disconnectWallet}>
              <ConnectBtn>Disconnect</ConnectBtn>
            </div>
          </ActionContainer>
        </Modal>
      )}
    </>
  );
};

export default ConnectionModal;

const InfoWrapper = styled.div`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  a{
    color: ${({ theme }) => theme.highlight};
  }
`;

const ConnectBtn = styled.div`
  padding: 11px 53px;
  border-radius: 30px;
  border: 0px;
  background: ${({ theme }) => theme.yellow};
  border: 1px solid #e5b910;
  font-weight: 600;
  color: ${({ theme }) => theme.white};
`;

const WalletName = styled.p`
  color: ${({ theme }) => theme.flexiblegrey};
  font-size: ${({ theme }) => theme.textXXs};
  text-align: center;
  font-weight: ${({ theme }) => theme.textNormal};
  margin-bottom: 0px;
`;

interface ICardWrapper {
  show?: boolean;
}
const CardWrapper = styled.button<ICardWrapper>`
  cursor: pointer;
  position: relative;
  display: ${p => (p.show === false ? "none" : "flex")};
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  border: none;
  box-sizing: border-box;
  border-radius: 5px;


  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background: #d3d3d3;
    border-radius: 8px;
  }

  & .apy {
    color: ${p => p.theme.fontAlt};
    font-size: 14px;
    font-weight: 600;
  }

  & .check {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(30%, -30%);
  }
`;

const SectionWrapper = styled.div`
  //margin-top: 40px;
`;

const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  @media (min-width: 768px) {
    flex-direction: column;
    justify-content: space-around;
    //justify-content: space-between;
  }
`;

const ActionContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;

  & div {
    cursor: pointer;
    display: flex;
    align-items: center;

    & p {
      margin-left: 8px;
    }
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: center;
  }
`;

const Recommended = styled.div`
 background: #00C0871A;
  color: #00C087;
  text-align:center;
  font-size: 8px;
  padding: 6px;
  border-radius: 8px;
  font-weight 600;
`

const ConnectedWalletDetail = styled.div`
  background: #E5B91005;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #E5B9101A;
  margin-bottom: 10px;
`

export const ConnectedWalletContainer = styled.div`
  background: #E5B91005;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #E5B9101A;
  margin-bottom: 10px;

  div{
    display: flex;
    justify-content: space-between;
  }
`

const DetailHeading = styled.p`
  margin-bottom: 3px;
  color: #6B6B6B;
  line-height: 15px;
  font-size: 12px;
}
`

const DetailValue = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.descriptionColor};
  margin-bottom: 0px;
  font-weight: 500;
`

const Address = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.textColor};
`

const Balance = styled.p`
  font-size: ${({ theme }) => theme.textXs};
  font-weight: 600;
  line-height: 21px
  margin-bottom: 0;
  color: ${({ theme }) => theme.descriptionColor};
`