import { InjectedConnector } from "@web3-react/injected-connector";
import { ConnectorNames } from "./types";
import _const from "../_const";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { DisconnectFromWallet } from "./useAuth";
let injected = new InjectedConnector({
  supportedChainIds: [1, 56, 137, 80001, 97, 42161]
});

export const connectorsByName = (connectorName, chainId) => {
  try {
    if (connectorName === ConnectorNames.Injected) {
      return injected;
    }

    if (connectorName === ConnectorNames.WalletConnect) {
      if (chainId === 56) {
        const walletconnect = new WalletConnectProvider({
          rpc: { 56: "https://bsc-dataseed1.ninicoin.io" },
          chainId,
          qrcodeModalOptions: {
            mobileLinks: ["metamask"]
          }
        });

        // Subscribe to session disconnection
        walletconnect.on("disconnect", (code, reason) => {
          DisconnectFromWallet();
        });

        return walletconnect;
      } else {
        //If Matic Network 137
        const wcProviderMATIC = new WalletConnectProvider({
          rpc: {
            137: process.env.REACT_APP_RPC_URL,
            80001: process.env.REACT_APP_RPC_URL,
            42161: process.env.REACT_APP_RPC_URL,
          },

          chainId,
          qrcodeModalOptions: {
            mobileLinks: ["metamask", "trust"]
          }
        });
        wcProviderMATIC.on("disconnect", (code, reason) => {
          DisconnectFromWallet();
        });
        return wcProviderMATIC;
      }
    }
  } catch (error) {
    console.log("ERROR HAS BEEN CAUGHT", error);
  }
};

export const getLibrary = provider => {
  //const binanceProvider = window.BinanceChain;
  const walletConnectProvider = provider.http;
  const metamaskProvider = window.ethereum;
  if (typeof walletConnectProvider !== "undefined") {
    //WalletConnect
    window.sessionStorage.removeItem(_const.WEB3SETPROVIDER);
    window.sessionStorage.removeItem(
      _const.WEB3_WALLETCONNECT_HAS_DISCONNECTED
    );
    window.sessionStorage.setItem(_const.WEB3SETPROVIDER, "walletConnect");
  } else if (
    typeof metamaskProvider !== "undefined" &&
    typeof walletConnectProvider === "undefined"
  ) {
    //Metamask
    window.sessionStorage.removeItem(_const.WEB3SETPROVIDER);
    window.sessionStorage.removeItem(
      _const.WEB3_WALLETCONNECT_HAS_DISCONNECTED
    );
    window.sessionStorage.setItem(_const.WEB3SETPROVIDER, "metamask");
  }

  return provider;
};
