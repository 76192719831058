import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  NavBarContainer,
  NavList,
  LinkItems,
  MobileNavToggleContainer,
  MobileNavToggleBar,
  MobileNavContainer,
  MobileNavList,
  MobileNav,
  DesktopMenuOptions,
  DesktopNavContainer,
  DesktopLinkItems
} from "./partialstyles";
import ConnectWallet from "../wallets/connectwallet";
import { togglethemeswitch } from "../methods/actions";

const NavBar = () => {
  const dispatch = useDispatch();
  const theme = useSelector(state => state.General.theme);
  const [toggleButton, setToggleButton] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleClick = () => {
    dispatch(togglethemeswitch(!theme));
  };

  return (
    <NavBarContainer className="container">

      <span>
        <a href="https://wicrypt.com/" rel="noreferrer" target="_blank">
          {" "}
          {theme ? (
            <img src="/assets/logodark.svg" alt="logo" />
          ) : (
            <img src="/assets/Group.svg" alt="logo" />
          )}
        </a>
        <DesktopMenuOptions onClick={() => setToggleMenu(!toggleMenu)} src={theme ? "/assets/darkmenuoptions.svg" : "/assets/menuoptions.svg"} alt="menu options" />
      </span>
      {toggleMenu &&
        <DesktopNavContainer>
          <MobileNavList>
            <a href="https://nft.wicrypt.com/" rel="noreferrer" target="_blank">
              <DesktopLinkItems>
              <span> <img src="/assets/hubs.svg" alt="explorer icon" /></span> Hotspot Hubs
              </DesktopLinkItems>
            </a>
            <a href="https://scan.wicrypt.com/" rel="noreferrer" target="_blank">
              <DesktopLinkItems>
                <span> <img src="/assets/explorer.svg" alt="explorer icon" /></span>Explorer
              </DesktopLinkItems>
            </a>
          </MobileNavList>
        </DesktopNavContainer>}
      <MobileNav>
        <LinkItems onClick={handleClick}>
          {theme ? (
            <img src="assets/sun.svg" alt="sun" />
          ) : (
            <img src="assets/moon.svg" alt="moon" />
          )}
        </LinkItems>
        <MobileNavToggleContainer
          onClick={() => setToggleButton(!toggleButton)}
        >
          <MobileNavToggleBar />
          <MobileNavToggleBar active={toggleButton} />
          <MobileNavToggleBar />
        </MobileNavToggleContainer>
      </MobileNav>

      {toggleButton ? (
        <MobileNavContainer>
          <MobileNavList>
            <a href="https://nft.wicrypt.com/" rel="noreferrer" target="_blank">
              <DesktopLinkItems>
              <span> <img src="/assets/hubs.svg" alt="explorer icon" /></span> Hotspot Hubs
              </DesktopLinkItems>
            </a>
            <a href="https://scan.wicrypt.com/" rel="noreferrer" target="_blank">
              <DesktopLinkItems>
                <span> <img src="/assets/explorer.svg" alt="explorer icon" /></span>Explorer
              </DesktopLinkItems>
            </a>
            <LinkItems>
              <ConnectWallet />
            </LinkItems>
          </MobileNavList>
        </MobileNavContainer>
      ) : (
        ""
      )}

      <NavList>
        <LinkItems>
          <ConnectWallet />
        </LinkItems>
        <LinkItems onClick={handleClick}>
          {theme ? (
            <img src="assets/sun.svg" alt="sun" />
          ) : (
            <img src="assets/moon.svg" alt="moon" />
          )}
        </LinkItems>
      </NavList>
    </NavBarContainer>
  );
};

export default NavBar;
