import React from "react";
import { SmallText, FooterContainer, CopyRight } from "./partialstyles";

const Footer = () => {
  return (
    <FooterContainer>
      <CopyRight className="col-lg-5 col-xs-12 col-sm-12">
        {" "}
        &copy; {new Date().getFullYear()} Wicrypt{" "}
      </CopyRight>
      <div className="col-lg-3 col-xs-12 col-sm-12">
          <SmallText 
            href="https://drive.google.com/file/d/1ovCK9xC-vh1wOkNYksrfNV6EDtdxkeiz/view"
            rel="noreferrer"
            target="_blank" >
            Audited by Vidma
            <img src={"./assets/shield.svg"} alt="audit" />
          </SmallText>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <SmallText
            href="https://wicrypt.com/help/"
            target="_blank"
            className="col-lg-1 col-xs-3 col-sm-3"
          >
            {" "}
            Help
          </SmallText>
          <SmallText
            href="https://wicrypt.com/privacy-policy.html"
            target="_blank"
            className="col-lg-1 col-xs-3 col-sm-3"
          >
            {" "}
            Privacy Policy
          </SmallText>
          <SmallText
            href="https://wicrypt.com/terms-condition.html"
            target="_blank"
            className="col-lg-1 col-xs-3 col-sm-3"
          >
            {" "}
            Terms of Use
          </SmallText>
        </div>
      </div>

    </FooterContainer>
  );
};

export default Footer;
