import { useSelector } from "react-redux";
import {
    Banner
} from "./partialstyles";


const TopBanner = () => {

    const theme = useSelector(state => state.General.theme);

    return (
        <Banner>
            <p> WNT Staking v2 is live </p>
        </Banner>
    );
};

export default TopBanner;
