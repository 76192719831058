import styled from "styled-components";
import { device } from "../../theme";

export const NavBarContainer = styled.nav`
  padding: 20px;
  margin-bottom: 30px;
  @media ${device.laptop} {
    margin-bottom: 50px;
    padding: 50px;
    padding-bottom: 30px;
  }
`;

export const SmallText = styled.a`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.lightblackColor};
  text-decoration: none;
  margin-right: 10px;
`;

export const FooterContainer = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  padding: 10px;
  flex-wrap: wrap;
  @media ${device.laptop} {
    text-align: justify;
    margin: 50px;
    margin-bottom: 0px;
  }
`;

export const CopyRight = styled.p`
  font-size: ${({ theme }) => theme.textXXs};
  color: ${({ theme }) => theme.lightblackColor};
  margin-top: 20px;
`;

export const Button = styled.button`
  padding: 11px 53px;
  border-radius: 30px;
  border: 0px;
  background: #e5b910;
  font-weight: 600;
  color: ${({ theme }) => theme.white};

  &:disabled {
    background-color: ${({ theme }) => theme.disabledColor};
  }
`;
export const NavList = styled.ul`
  display: none;
  @media ${device.laptop} {
    display: inline-flex;
    float: right;
    list-style: none;
    align-items: center;
  }
`;
export const MobileNavList = styled.ul`
  list-style: none;
  align-items: center;
  cursor: pointer;
  padding-left: 0px !important;
  a{
    text-decoration: none;
    color: ${({ theme }) => theme.textColor} !important; !
   
  }
`;
export const LinkItems = styled.li`
  font-size: ${({ theme }) => theme.textXXs};
  //color: ${({ theme }) => theme.mainColor};
  margin-left: 0px;
  font-weight: 500;
  cursor: pointer;
  @media ${device.laptop} {
    margin-left: 30px;
  }
`;

export const MobileNav = styled.div`
  float: right;
  display: inline-flex;
  @media ${device.laptop} {
    display: none;
  }
`;
export const MobileNavToggleContainer = styled.div`
  cursor: pointer;
  margin-left: 20px;
`;

export const MobileNavToggleBar = styled.div`
  width: 30px;
  height: 4px;
  background-color: ${({ theme }) => theme.flexiblegrey};
  margin: 6px 0;
  transition: 0.4s;
  transform: ${(props) => (props.active ? "rotate(25deg)" : "none")};
`;

export const MobileNavContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.mainColor};
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100000;
  top: 10%;
  right: 5%;
  padding: 10px;
  border-radius: 8px;
`;

export const Banner = styled.div`
  background-color: ${({ theme }) => theme.highlight};
  display: flex;
  height:67px;
  justify-content: center;
  align-items: center;

  p{
    font-size: 1.25rem;
    font-weight: 700;
    color: #fefefe;
  }
`;

export const DesktopMenuOptions = styled.img`
  display: none;

  @media ${device.laptop} {
    display: inline;
    margin-top: 0.5rem;
    margin-left: 2rem;
    cursor: pointer;
  }
`

export const DesktopNavContainer = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.mainColor};
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 100000;
  top: 20%;
  left: 10%;
  padding: 10px;
  border-radius: 8px;
`;

export const DesktopLinkItems = styled.li`
  font-size: ${({ theme }) => theme.textXXs};
  margin-left: 0px;
  font-weight: 500;
  cursor: pointer;
  cursor: pointer;
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  :hover{
    background-color: ${({ theme }) => theme.highlight2};
    border-radius: 8px;
  }
`;