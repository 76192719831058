import React from "react";
import Modal from "../reusables/modal";
import {
  OptionButton,
  SubTitle,
  AmountBox,
  FlexContainer,
  WalletDetailContainer,
  Value,
  StakeAmount,
  ButtonContainer,
  TermaandConditions,
  RadioButton,
  Symbol,
  ErrMsg,
  InfoIcons,
} from "./stakestyle";
import { ButtonState } from "../reusables/button";
import {
  getFormDetails,
  stakeUserToken,
  toggletermsandconditions,
} from "../methods/actions";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../reusables/loader";
import { numberWithCommaswithoutdecimals } from "../methods/helper";
import Info from "./info";

const Stake = () => {
  const dispatch = useDispatch();
  const stakeType = useSelector((state) => state.General.stakeType);
  const termsandconditions = useSelector(
    (state) => state.General.termsandconditions
  );
  const amttoStake = useSelector((state) => state.General.amttoStake);
  const rewards = useSelector((state) => state.StakingReducer.rewards);
  const availableBalance = useSelector(
    (state) => state.General.availableBalance
  );
  const tokenAddress = useSelector((state) => state.General.tokenAddress);
  const stakingToken = useSelector((state) => state.LoaderReducer.stakingToken);
  const stakingFailedMsg = useSelector(
    (state) => state.StakingReducer.stakingFailedMsg
  );
  const stakingInfo = useSelector((state) => state.StakingReducer.stakingInfo);
  const timeLimit = useSelector((state) => state.StakingReducer.timeLimit);
  const theme = useSelector((state) => state.General.theme);
  const infoModal = useSelector((state) => state.General.infoModal);

  return (
    <React.Fragment>
      {infoModal ? <Info /> : null}
      <Modal
        title={
          <React.Fragment>
            {" "}
            {!theme ? (
              <img
                src="/assets/stakelightthemeicon.svg"
                alt={"stake-icon"}
                className={"mr-1"}
              />
            ) : (
              <img
                src="/assets/stakedarkthemeicon.svg"
                alt={"stake-icon"}
                className={"mr-1"}
              />
            )}{" "}
            Stake
          </React.Fragment>
        }
        visible={true}
        width={"30%"}
      >
        <div>
          <div>
            <SubTitle> Type</SubTitle>
            <div className="row">
              <div
                className="col-lg-4"
                onClick={() =>
                  dispatch(
                    getFormDetails({
                      props: ["stakeType"],
                      value: 0,
                    })
                  )
                }
              >
                <OptionButton active={stakeType === 0}> Flexible</OptionButton>
              </div>
              <div
                onClick={() =>
                  dispatch(
                    getFormDetails({
                      props: ["stakeType"],
                      value: 1,
                    })
                  )
                }
                className="col-lg-4"
              >
                <OptionButton active={stakeType != 0}> Locked</OptionButton>
              </div>
              <InfoIcons
                className="col-lg-4"
                onClick={() =>
                  dispatch(
                    getFormDetails({
                      props: ["infoModal"],
                      value: true,
                    })
                  )
                }
              >
                <img src="/assets/info.svg" alt="info" />
              </InfoIcons>
            </div>
          </div>
          {stakeType != 0 ? (
            <div>
              <SubTitle> Duration</SubTitle>
              <div className="row">
                <div className="col-lg-4">
                  <OptionButton
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["stakeType"],
                          value: 1,
                        })
                      )
                    }
                    active={stakeType === 1}
                  >
                    {" "}
                    3 Months
                  </OptionButton>
                </div>
                <div className="col-lg-4">
                  <OptionButton
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["stakeType"],
                          value: 2,
                        })
                      )
                    }
                    active={stakeType === 2}
                  >
                    {" "}
                    6 Months
                  </OptionButton>
                </div>
                <div className="col-lg-4"></div>

                <div className="col-lg-4">
                  <OptionButton
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["stakeType"],
                          value: 3,
                        })
                      )
                    }
                    active={stakeType === 3}
                  >
                    {" "}
                    9 Months
                  </OptionButton>
                </div>
                <div className="col-lg-4">
                  <OptionButton
                    onClick={() =>
                      dispatch(
                        getFormDetails({
                          props: ["stakeType"],
                          value: 4,
                        })
                      )
                    }
                    active={stakeType === 4}
                  >
                    {" "}
                    12 Months
                  </OptionButton>
                </div>
              </div>
            </div>
          ) : (
            <div />
          )}
          <div className="row">
            <div className="col-lg-12" style={{display: "flex", alignItems: "center"}}>
              <AmountBox>
                <FlexContainer>
                  <SubTitle> AMOUNT</SubTitle>
                  <SubTitle>
                    {" "}
                    AVAILABLE:{" "}
                    {numberWithCommaswithoutdecimals(availableBalance)} WNT
                  </SubTitle>
                </FlexContainer>
                <div className="row">
                  <div className="col-lg-7">
                    <StakeAmount
                      onChange={(e) =>
                        dispatch(
                          getFormDetails({
                            props: ["amttoStake"],
                            value: e.target.value,
                          })
                        )
                      }
                      value={amttoStake}
                      placeholder={0}
                    />
                  </div>

                  <div className="col-lg-4">
                    <WalletDetailContainer>
                      <OptionButton
                        style={{marginBottom: "0px"}}
                        onClick={() =>
                          dispatch(
                            getFormDetails({
                              props: ["amttoStake"],
                              value:
                                availableBalance > 0.01
                                  ? availableBalance - 0.01
                                  : 0,
                            })
                          )
                        }
                      >
                        {" "}
                        MAX
                      </OptionButton>

                      <img src="/assets/wicrypticon.svg" alt="icon" />
                      <Symbol> WNT</Symbol>
                    </WalletDetailContainer>
                  </div>
                </div>
              </AmountBox>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <FlexContainer>
                <SubTitle> Best Deal Reward (APR)</SubTitle>
                <Value> {rewards}% APR</Value>
              </FlexContainer>
            </div>
            <div className="col-lg-12">
              <FlexContainer>
                <SubTitle> Estimated Interest</SubTitle>
                <Value>
                  {" "}
                  {numberWithCommaswithoutdecimals(
                    (rewards * amttoStake) / 100
                  )}
                  WNT
                </Value>
              </FlexContainer>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <TermaandConditions>
                <RadioButton
                  onChange={() =>
                    dispatch(toggletermsandconditions(!termsandconditions))
                  }
                  type="checkbox"
                  checked={termsandconditions}
                />
                I agree to the terms above.
              </TermaandConditions>
            </div>
          </div>
          {stakingFailedMsg ? (
            <ErrMsg> {stakingFailedMsg}</ErrMsg>
          ) : (
            <div> </div>
          )}
          <ButtonContainer>
            <div className="col-lg-12 t-center ">
              {stakingToken ? (
                <ButtonState
                  fontSize={"0.8rem"}
                  buttonClass={"secondary"}
                  label={<Loader color={"#E5B910"} />}
                />
              ) : (
                <ButtonState
                  fontSize={"0.8rem"}
                  buttonClass={"secondary"}
                  label={"Stake"}
                  onClick={() =>
                    dispatch(
                      stakeUserToken(
                        amttoStake,
                        stakeType,
                        tokenAddress,
                        termsandconditions,
                        availableBalance,
                        stakingInfo[3],
                        stakingInfo[2],
                        timeLimit
                      )
                    )
                  }
                />
              )}
            </div>
          </ButtonContainer>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default Stake;
